import { sortBy } from "lodash";
import debounce from "lodash/debounce";
import moment from "moment";
import { X } from "phosphor-react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FeatureToggleContext } from "../../context/FeatureToggleContext";
import { isFeatureEnabled } from "../../functions/consult/isFeatureEnabled";
import { getMyPrograms } from "../../functions/getConsults";
import { useSession } from "../../functions/useSession";
import { FeatureNameEnum } from "../../hooks/useFeatureToggle";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { create as createAsyncOnlyReasonModalProps } from "../../types/components/queue/asyncOnlyReasonModalProps";
import { ConsultCardFilterProps } from "../../types/components/queue/consultCardFilterProps";
import { Clinician, ConsultReasonForVisitEnum, Program } from "../../types/consult-api";
import { CountdownTimer } from "../CountdownTimer";
import { consultScoreOptions } from "./ConsultCardScoreSymbol";
import { AsyncOnlyReasonModal } from "./QueueModals";

const debouncedFetch = debounce((fn, value, ...args) => fn(value, ...args), 800);

const ConsultCardFilter = (props: ConsultCardFilterProps) => {
    const [session] = useSession();
    const sessionUser = session?.user as any;
    const clinician = sessionUser?.clinician as Clinician;

    const [ls, setLS] = useLocalStorage("smd.countdowntimer", { expiresAt: "" });
    const [expiresAt, setExpiresAt] = useState(ls.expiresAt);
    const [isAsyncConsultsOnly, setIsAsyncConsultsOnly] = useState(ls.expiresAt && moment(ls.expiresAt).isAfter(moment()));
    const [isFiltered, setIsFiltered] = useState(false);
    const [isReasonForVisitSideEffectOnly, setIsReasonForVisitSideEffectOnly] = useState(false);
    const [partners, setPartners] = useState<string[]>([]);
    const [programs, setPrograms] = useState<string[]>([]);
    const [score, setScore] = useState<number | undefined>(undefined);
    const [reasons, setReasons] = useState<ConsultReasonForVisitEnum[]>([]);
    const [showAsyncOnlyReason, setShowAsyncOnlyReason] = useState(false);
    const { features } = useContext(FeatureToggleContext);
    const toast = useRef<Toast | null>(null);

    const [partnerOptions, setPartnerOptions] = useState<any[]>([]);
    const [programOptions, setProgramOptions] = useState<any[]>();
    const [scoreOptions, setScoreOptions] = useState<any[]>();

    useEffect(() => {
        getMyPrograms().then((result) => {
            const filteredPrograms = result.filter((program: Program) => !program.partnerName.includes("Sandbox") && !program.name.includes("Sandbox"));

            const partners = (() => {
                const partners: any = {};
                filteredPrograms.map((program) => {
                    if (!partners[program.partnerGuid]) {
                        partners[program.partnerGuid] = {
                            label: program.partnerName,
                            value: program.partnerGuid,
                        };
                    }
                });
                return Object.values(partners);
            })();

            const programs = filteredPrograms.map((program: Program) => ({
                label: program.name,
                value: program.guid,
            }));

            setPartnerOptions([...sortBy(partners, "label")]);
            setProgramOptions([...sortBy(programs, "label")]);
            setScoreOptions(consultScoreOptions);
        });
    }, []);

    const consultDifficultyTemplate = (option: any) => {
        return (
            <div className="flex align-items-center" style={{ gap: "4px" }}>
                <img alt={option.label} src={option.symbolPath} style={{ width: "32px" }} />
                <div>{option.label}</div>
            </div>
        );
    };

    useEffect(() => {
        setIsFiltered(isAsyncConsultsOnly || partners.length > 0 || programs.length > 0 || score || reasons.length > 0);
        if (isAsyncConsultsOnly) {
            // Handle the case where the async consults only filter is true by default
            props.methods.onIsAsyncConsultsOnlyChange(true);
        }
    }, [isAsyncConsultsOnly, partners, programs, score, reasons]);

    const applyIsAsyncConsultsOnly = (value: boolean, selectedDuration?: string) => {
        setIsAsyncConsultsOnly(value);
        props.methods.onIsAsyncConsultsOnlyChange(value);

        if (value) {
            // Use the selected duration if applicable; otherwise, use the default
            const durationInSeconds = selectedDuration ? moment.duration(selectedDuration).asSeconds() : clinician.asyncOnlyFilterDurationSeconds;
            const expiresAt = moment().add(durationInSeconds, "seconds").toISOString();
            setExpiresAt(expiresAt);
            setLS({ expiresAt });
        } else {
            setExpiresAt("");
            setLS({ expiresAt: "" });
        }
    };

    const _onIsAsyncConsultsOnlyChange = (e: InputSwitchChangeEvent) => {
        const value = e.value;

        if (!isFeatureEnabled(features, FeatureNameEnum.NextUpQueueOverride)) {
            if (value) {
                // Next Up Queue only - Reason is required before applying the filter
                setShowAsyncOnlyReason(true);
                return;
            } else {
                props.methods.logAsyncOnlyFilterActivity(false);
            }
        }
        applyIsAsyncConsultsOnly(value);
    };

    const _onPartnerChange = (e: MultiSelectChangeEvent) => {
        const value = e.value;
        setPartners(value);
        debouncedFetch(props.methods.onPartnerChange, value);
    };

    const _onProgramChange = (e: MultiSelectChangeEvent) => {
        const value = e.value;
        setPrograms(value);
        debouncedFetch(props.methods.onProgramChange, value);
    };

    const _onScoreChange = (e: MultiSelectChangeEvent) => {
        const selectedValue: number = Number(e.value);
        setScore(selectedValue);
        const selectedOption = scoreOptions?.find((opt) => opt.value === selectedValue);

        if (selectedOption) {
            const { min, max } = selectedOption;
            debouncedFetch(props.methods.onConsultDifficultyChange, min, max);
        } else {
            debouncedFetch(props.methods.onConsultDifficultyChange, 0, 100);
        }
    };

    const _onIsReasonForVisitSideEffectOnlyChange = (e: InputSwitchChangeEvent) => {
        const value = e.value;
        setIsReasonForVisitSideEffectOnly(value);
        if (value) {
            setReasons([ConsultReasonForVisitEnum.SideEffect]);
            props.methods.onReasonForVisitChange([ConsultReasonForVisitEnum.SideEffect]);
        } else {
            setReasons([]);
            props.methods.onReasonForVisitChange([]);
        }
    };

    const onExpire = function () {
        setIsAsyncConsultsOnly(false);
        props.methods.onIsAsyncConsultsOnlyChange(false);
        props.methods.logAsyncOnlyFilterActivity(false);

        setExpiresAt("");
        setLS({ expiresAt: "" });
    };

    const clearFilter = () => {
        setIsAsyncConsultsOnly(false);
        props.methods.onIsAsyncConsultsOnlyChange(undefined);
        props.methods.logAsyncOnlyFilterActivity(false);

        setPartners([]);
        props.methods.onPartnerChange(undefined);

        setPrograms([]);
        props.methods.onProgramChange(undefined);

        setScore(undefined);
        props.methods.onConsultDifficultyChange(undefined, undefined);

        setReasons([]);
        props.methods.onReasonForVisitChange(undefined);

        setIsReasonForVisitSideEffectOnly(false);
        setIsFiltered(false);

        setExpiresAt("");
        setLS({ expiresAt: "" });
    };

    const removePartner = (partnerName: string) => {
        const value = partners.filter((p) => p !== partnerName);
        setPartners(value);
        props.methods.onPartnerChange(value);
    };

    const removeProgram = (programGuid: string) => {
        const value = programs.filter((p) => p !== programGuid);
        setPrograms(value);
        props.methods.onProgramChange(value);
    };

    return (
        <>
            <Toast ref={toast} onHide={() => {}} />
            {isFeatureEnabled(features, FeatureNameEnum.FilterByProgram) && (
                <>
                    <MultiSelect
                        value={partners}
                        options={partnerOptions}
                        onChange={_onPartnerChange}
                        placeholder="Filter by Partners"
                        className="w-full partners-filter"
                        maxSelectedLabels={1}
                        filter
                    />
                    <MultiSelect
                        value={programs}
                        options={programOptions}
                        onChange={_onProgramChange}
                        placeholder="Filter by Programs"
                        className="w-full programs-filter"
                        maxSelectedLabels={1}
                        filter
                    />
                </>
            )}
            {isFeatureEnabled(features, FeatureNameEnum.NextUpQueueOverride) && isFeatureEnabled(features, FeatureNameEnum.FilterByConsultDifficultyScore) && (
                <Dropdown
                    value={score}
                    options={scoreOptions}
                    onChange={_onScoreChange}
                    placeholder="Filter by Clinician Rarity"
                    className="w-full clinician-rarity-filter"
                    optionLabel="label"
                    itemTemplate={consultDifficultyTemplate}
                />
            )}
            {isFeatureEnabled(features, FeatureNameEnum.FilterByReasonForVisit) && (
                <div className={"side-effects-switch"}>
                    <InputSwitch checked={isReasonForVisitSideEffectOnly} onChange={_onIsReasonForVisitSideEffectOnlyChange} />
                    <p className={"text-sm font-normal"}>Side Effect Consults Only</p>
                </div>
            )}
            {isFeatureEnabled(features, FeatureNameEnum.FilterByAsyncConsultsOnly) && (
                <>
                    <div className={"async-consults-switch"}>
                        <InputSwitch checked={isAsyncConsultsOnly} onChange={_onIsAsyncConsultsOnlyChange} />
                        <p className={"text-sm font-normal"}>Async Consults Only</p>
                        {!isFeatureEnabled(features, FeatureNameEnum.NextUpQueueOverride) && <CountdownTimer expiresAt={expiresAt} onExpire={onExpire} />}
                    </div>
                    {showAsyncOnlyReason && (
                        <AsyncOnlyReasonModal
                            completeFn={async ({ reason: selectedReason, selectedDuration }) => {
                                await props.methods.logAsyncOnlyFilterActivity(true, selectedReason, selectedDuration, toast);
                                applyIsAsyncConsultsOnly(true, selectedDuration);
                                setShowAsyncOnlyReason(false);
                            }}
                            cancelFn={() => setShowAsyncOnlyReason(false)}
                            isVisible={showAsyncOnlyReason}
                            methods={createAsyncOnlyReasonModalProps().methods}
                        />
                    )}
                </>
            )}
            {isFiltered && (
                <div className="filter-notification">
                    <span className={"filter-notification-text"}>
                        <p className="text-sm font-normal">Your queue is being filtered.</p>
                        <span className="text-sm clear-queue-filter-button" onClick={clearFilter}>
                            Show all consults?
                        </span>
                    </span>
                    {partners.length > 0 && (
                        <div className="text-sm font-semibold filtered-partners-list">
                            Partners:
                            {partners.map((partnerGuid) => {
                                const partner = partnerOptions?.find((p) => p.value === partnerGuid);
                                return (
                                    <p key={partnerGuid} className="text-sm font-normal filtered-item">
                                        <span className="filtered-icon">
                                            <X size={18} onClick={() => removePartner(partnerGuid)} />
                                        </span>
                                        <span className="filtered-label">{partner?.label}</span>
                                    </p>
                                );
                            })}
                        </div>
                    )}
                    {programs.length > 0 && (
                        <div className="text-sm font-semibold filtered-programs-list">
                            Programs:
                            {programs.map((programGuid) => {
                                const program = programOptions?.find((p) => p.value === programGuid);
                                return (
                                    <p key={programGuid} className="text-sm font-normal filtered-item">
                                        <span className="filtered-icon">
                                            <X size={18} onClick={() => removeProgram(programGuid)} />
                                        </span>
                                        <span className="filtered-label">{program?.label}</span>
                                    </p>
                                );
                            })}
                        </div>
                    )}
                    {score && (
                        <div className="text-sm font-semibold filtered-difficulty-scores-list">
                            Clinician Rarity:
                            <div className="text-sm font-normal filtered-item" style={{ alignItems: "center", marginTop: "8px" }}>
                                <span className="filtered-icon">
                                    <X
                                        size={18}
                                        onClick={() => {
                                            setScore(undefined);
                                            props.methods.onConsultDifficultyChange(0, 100);
                                        }}
                                    />
                                </span>
                                <span className="filtered-label">
                                    {(() => {
                                        const option = scoreOptions?.find((o) => o.value === score);
                                        return option ? (
                                            <div className="flex align-items-center" style={{ gap: "4px" }}>
                                                <img alt={option.label} src={option.symbolPath} style={{ width: "32px" }} />
                                                <div>{option.label}</div>
                                            </div>
                                        ) : null;
                                    })()}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ConsultCardFilter;
