import { Button } from "primereact/button";
import React, { useContext } from "react";
import { FeatureToggleContext } from "../../context/FeatureToggleContext";
import { isFeatureEnabled } from "../../functions/consult/isFeatureEnabled";
import { FeatureNameEnum } from "../../hooks/useFeatureToggle";

enum ConsultCardScoreEnum {
    NearlyAll = 1,
    Most = 2,
    Some = 3,
    Few = 4,
    VeryFew = 5,
}

export const consultScoreOptions = [
    {
        label: "Nearly all clinicians",
        value: ConsultCardScoreEnum.NearlyAll,
        symbolPath: "consult/difficultyScoreSymbols/grey-circle-nearly-all.svg",
        min: 80,
        max: 100,
    },
    {
        label: "Most clinicians",
        value: ConsultCardScoreEnum.Most,
        symbolPath: "consult/difficultyScoreSymbols/green-diamond-most.svg",
        min: 60,
        max: 79,
    },
    {
        label: "Some clinicians",
        value: ConsultCardScoreEnum.Some,
        symbolPath: "consult/difficultyScoreSymbols/blue-star-some.svg",
        min: 40,
        max: 59,
    },
    {
        label: "Few clinicians",
        value: ConsultCardScoreEnum.Few,
        symbolPath: "consult/difficultyScoreSymbols/purple-stars-few.svg",
        min: 20,
        max: 39,
    },
    {
        label: "Very few clinicians",
        value: ConsultCardScoreEnum.VeryFew,
        symbolPath: "consult/difficultyScoreSymbols/gold-stars-very-few.svg",
        min: 0,
        max: 19,
    },
];

const getConsultCardScoreIcon = function (value?: number) {
    const score = value ?? 100;
    return consultScoreOptions.find((opt) => score >= opt.min && score <= opt.max) || consultScoreOptions[0];
};

const ConsultCardScoreSymbol = (props: { score?: number }) => {
    const difficulty = getConsultCardScoreIcon(props.score);
    const { features } = useContext(FeatureToggleContext);

    if (!isFeatureEnabled(features, FeatureNameEnum.NextUpQueueOverride) || !isFeatureEnabled(features, FeatureNameEnum.FilterByConsultDifficultyScore)) {
        return <></>;
    }
    return (
        <Button
            icon={<img src={difficulty.symbolPath} width="32" alt={`${difficulty.label} icon`} style={{ height: "24px" }} />}
            tooltip={`${difficulty.label} can accept this consult`}
            tooltipOptions={{ position: "left" }}
            className="p-button-icon-only"
            style={{ border: "none", background: "none", padding: "0", cursor: "default" }}
        />
    );
};

export default ConsultCardScoreSymbol;
