import * as Sentry from "@sentry/browser";
import React from "react";
import { ClinicianApi } from "../../clinician-api";
import { ConsultQueue, ConsultQueueVisitStatusEnum } from "../../consult-api";

export interface ConsultCardButtonProps {
    consult: ConsultQueue;
    isInProgress: boolean;
    methods: {
        acceptConsult: (toast: React.MutableRefObject<any>) => Promise<boolean>;
        getCTA: (toast: React.MutableRefObject<any>) => any;
    };
    disabled?: boolean;
}

export function create(consult: ConsultQueue): ConsultCardButtonProps {
    return {
        consult,
        isInProgress:
            consult.visitStatus === ConsultQueueVisitStatusEnum.InProgress ||
            consult.visitStatus === ConsultQueueVisitStatusEnum.ExternalInProgress ||
            consult.visitStatus === ConsultQueueVisitStatusEnum.InCall ||
            consult.visitStatus === ConsultQueueVisitStatusEnum.ReadyToResume ||
            consult.visitStatus === ConsultQueueVisitStatusEnum.Waiting ||
            consult.visitStatus === ConsultQueueVisitStatusEnum.Scheduled,

        methods: {
            acceptConsult: async function (toast: React.MutableRefObject<any>) {
                const client = new ClinicianApi();
                try {
                    await client.consultQueue.consultQueueAcceptUpdate(consult.guid, consult);
                    return true;
                } catch (err) {
                    Sentry.captureException(err);
                    toast.current?.show({ severity: "error", sticky: true, summary: "Uh oh!", detail: err.response?.data?.detail, className: "error-toast" });
                }
                return false;
            },

            getCTA: function (toast: React.MutableRefObject<any>) {
                const open = function () {
                    window.location.assign(`/consult/${consult.guid}`);
                };
                switch (consult.visitStatus) {
                    case ConsultQueueVisitStatusEnum.InProgress:
                    case ConsultQueueVisitStatusEnum.ExternalInProgress:
                    case ConsultQueueVisitStatusEnum.InCall:
                        return {
                            label: "Resume",
                            onClickFn: () => open(),
                        };
                    case ConsultQueueVisitStatusEnum.ReadyToResume:
                        return {
                            label: "Resume",
                            onClickFn: async () => {
                                const res = await this.acceptConsult(toast);
                                if (res) {
                                    open();
                                }
                                return res;
                            },
                        };
                    case ConsultQueueVisitStatusEnum.Scheduled:
                    case ConsultQueueVisitStatusEnum.Waiting:
                        return {
                            label: "View",
                            onClickFn: () => open(),
                        };
                    case ConsultQueueVisitStatusEnum.Assigned:
                    case ConsultQueueVisitStatusEnum.Unassigned:
                        return {
                            label: "Accept",
                            onClickFn: async () => {
                                const res = await this.acceptConsult(toast);
                                if (res) {
                                    open();
                                }
                                return res;
                            },
                        };
                    default:
                        return {
                            label: "Accept",
                            onClickFn: async () => {
                                const res = await this.acceptConsult(toast);
                                if (res) {
                                    open();
                                }
                                return res;
                            },
                        };
                }
            },
        },
    };
}
