import { Skeleton } from "primereact/skeleton";
import React from "react";
import usePatientsFilter from "../../hooks/usePatientsFilter";
import { ConsultCardProps } from "../../types/components/queue/consultCardProps";
import calculateAge from "../../utils/calculateAge";

const ConsultCardPatientInformation = (props: ConsultCardProps) => {
    const episodeGuid: string = props.consult.episodeGuid || "";
    const { patient, isLoading } = usePatientsFilter(episodeGuid);

    let age = "";
    let genderInitial = "";
    let name = "External Patient";
    if (patient.firstName && patient.lastName) {
        name = `${patient.firstName} ${patient.lastName}`;
        age = patient.birthDate ? calculateAge(patient.birthDate) : "";
        genderInitial = patient.genderInitial;
    }
    return (
        <div className={"patient"}>
            <div className={"text-sm font-normal patient-name"}>
                <strong>{isLoading ? <Skeleton height="18px" borderRadius="4px" /> : name}</strong>
            </div>
            <div className={"text-sm font-normal patient-info"}>
                {isLoading ? (
                    <Skeleton height="18px" width={"40%"} borderRadius="4px" />
                ) : (
                    `${genderInitial ? genderInitial : ""} ${genderInitial ? "⋅" : ""} ${age ? age : ""}`
                )}
            </div>
        </div>
    );
};

export default ConsultCardPatientInformation;
