import { Info } from "phosphor-react";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { ConsultQueue, ProgramQueue } from "../../types/consult-api";
import ProgramCard from "./ProgramCard";

const ConsultCardHeader = (props: { consult: ConsultQueue; maxWidth: string; program: ProgramQueue }) => {
    const [showAboutVisit, setShowAboutVisit] = useState(false);
    const logoUrl = props.program.logo ? props.program.logo : "/logo.png";

    const name = `${props.program.name} ⋅ ${props.program.partnerName}`;
    return (
        <div className="flex align-items-center" style={{ maxWidth: props.maxWidth }}>
            <img style={{ width: "32px", height: "32px", borderRadius: "50%", marginRight: "10px" }} src={logoUrl} alt={""} />
            <div className="text-sm font-semibold" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <span title={name}>{name}</span>
            </div>
            &nbsp;
            <span style={{ cursor: "pointer", height: "19px" }}>
                <Info size={19} weight="fill" color={"#26aebc"} onClick={() => setShowAboutVisit(true)} />
                <Dialog
                    header={
                        <div className="flex align-items-center">
                            <img style={{ width: "56px", height: "56px", borderRadius: "50%", marginRight: "10px" }} src={logoUrl} alt={""} />
                            <div style={{ maxWidth: "450px" }}>
                                <div className="text-bold" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    {props.program.partnerName} - {props.program.name}
                                </div>
                            </div>
                        </div>
                    }
                    visible={showAboutVisit}
                    onHide={() => setShowAboutVisit(false)}
                    position={"center"}
                    resizable={false}
                >
                    <ProgramCard key={props.program.guid} program={props.program} consult={props.consult} />{" "}
                </Dialog>
            </span>
        </div>
    );
};

export default ConsultCardHeader;
