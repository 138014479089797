import {
    ArrowsClockwise,
    ArrowsLeftRight,
    Chats,
    ChatText,
    Clipboard,
    Clock,
    FirstAidKit,
    Flask,
    FlowerLotus,
    Folder,
    MapPin,
    Phone,
    Pill,
    Stethoscope,
    VideoCamera,
    X,
} from "@phosphor-icons/react";
import { getModalityType } from "../../../utils";
import { ConsultQueue } from "../../consult-api";

export const Icons = {
    ArrowsLeftRight,
    Clock,
    MapPin,
    Pill,
    ArrowsClockwise,
    FirstAidKit,
    Clipboard,
    Chats,
    ChatText,
    Phone,
    VideoCamera,
    Flask,
    X,
    FlowerLotus,
    Folder,
    Stethoscope,
};

export declare interface IconProps {
    name: string;
    key: string;
}

export interface ConsultCardIconsProps {
    consult: ConsultQueue;
    methods: {
        getIcons: () => any[];
    };
}

export function create(consult: ConsultQueue): ConsultCardIconsProps {
    return {
        consult,
        methods: {
            getIcons: () => [{ icon: "MapPin", label: consult.usState }, getModalityType(consult)],
        },
    };
}
