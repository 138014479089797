import * as Sentry from "@sentry/browser";
import { AxiosError, AxiosResponse } from "axios";
import { QueueModalProps } from "../../../components/queue/QueueModals";
import { ClinicianApi } from "../../clinician-api";
import { AsyncOnlyFilterReasons } from "../../consult-api";

export interface AsyncOnlyReasonModalProps extends QueueModalProps {
    methods: {
        getAsyncOnlyFilterData: () => Promise<AsyncOnlyFilterData>;
    };
}

interface RawAsyncOnlyFilterDuration {
    durationLength: string;
}

interface RawAsyncOnlyFilterData {
    reasons: Array<AsyncOnlyFilterReasons>;
    durations: Array<RawAsyncOnlyFilterDuration>;
}

export interface FormattedDuration {
    duration: string;
    time: string;
}

interface AsyncOnlyFilterData {
    reasons: Array<AsyncOnlyFilterReasons>;
    durations: Array<FormattedDuration>;
}

const handleError = (err: AxiosError | any) => {
    let errorMessage = err.response?.data?.message || err.message || "An unexpected error occurred. Please try again later.";
    throw new Error(errorMessage);
};

const formatDuration = (durationStr: any) => {
    const [hours, minutes, seconds] = durationStr.split(":").map(Number);
    if (hours > 0) {
        const hourLabel = hours === 1 ? "hour" : "hours";
        const minuteLabel = minutes > 0 ? ` ${minutes} ${minutes === 1 ? "minute" : "minutes"}` : "";
        return `${hours} ${hourLabel}${minuteLabel}`;
    } else if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
    } else {
        return `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
    }
};

const getTotalSeconds = (durationStr: string) => {
    const [hours, minutes, seconds] = durationStr.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

export function create(): AsyncOnlyReasonModalProps {
    return {
        isVisible: false,
        cancelFn: () => {},
        completeFn: () => {},
        methods: {
            getAsyncOnlyFilterData: async function (): Promise<AsyncOnlyFilterData> {
                const client = new ClinicianApi();
                try {
                    const response = (await client.consult.consultGetAsyncOnlyFilterDataRetrieve()) as unknown as AxiosResponse<RawAsyncOnlyFilterData>;
                    const rawData = response.data;
                    const formattedDurations: Array<FormattedDuration> = rawData.durations.map((duration) => ({
                        duration: duration.durationLength!,
                        time: formatDuration(duration.durationLength!),
                    }));
                    formattedDurations.sort((a, b) => getTotalSeconds(a.duration) - getTotalSeconds(b.duration));
                    return {
                        reasons: rawData.reasons,
                        durations: formattedDurations,
                    };
                } catch (err: AxiosError | any) {
                    Sentry.captureException(err);
                    handleError(err);
                }
                return { reasons: [], durations: [] };
            },
        },
    };
}
